import React from 'react';
import './footer.css'
const Footer = () => {

    return (
        <div className='footer-container'>
            <div className='footer'>
            <h2>Store exam</h2>
            <p>
            Все права защищены 
            +7 902 235 42 16 
            EMAIL: FOREXAMPLE@MAIL.COM 
            </p>
            </div>
        </div>
    )


}

export default Footer;